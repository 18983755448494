<template>
  <div>
    <a-form-model ref="formModelRef" :model="form" :label-col="{span:3}" :wrapper-col="{span:21}">
      <a-row>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label='库存：' prop="stock" :rules="[
            { required: true, message: '请输入' },
            { pattern: /^\+?[0-9][0-9]*$/, message: '格式有误' }
          ]">
            <a-input v-model.trim="form.stock" placeholder="请输入" :disabled="formDisabled || isCollect" />
          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label='重量：' prop="weight" :rules="weightValidate">
            <a-input v-model.trim="form.weight" placeholder="请输入" addonAfter="kg" :disabled="formDisabled || isCollect" />
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span='8' v-if="isCollect">
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="成本价：" prop="supplyPrice" :rules="decimalValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.supplyPrice" placeholder="请输入" addonBefore="¥" :disabled="formDisabled || isCollect" @input="handleSupplyPriceInput" />
              <a-popover>
                <div slot="content" class='tips'>商家购入商品的价格，商品的购进价值。如果不想透露成本价，填写的金额可以和供货价一样，加价率为0%</div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>

          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="折标价：" prop="points" :rules="beansValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.points" placeholder="请输入" addonBefore="¥" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>佣金 = 折标价 * 佣金比例</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="需使用TP数量：" prop="tp" :rules="beansValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.tp" placeholder="请输入" addonAfter="个" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>需使用TP数量</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="需使用ZP数量：" prop="zp" :rules="beansValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.zp" placeholder="请输入" addonBefore="¥" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>需使用ZP数量</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="需使用RP数量：" prop="rp" :rules="beansValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.rp" placeholder="请输入" addonAfter="个" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>需使用RP数量</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="需使用SP数量：" prop="sp" :rules="beansValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.sp" placeholder="请输入" addonAfter="个" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>需使用SP数量</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label='加价率：' prop="tradePriceMarkup" :rules="rateValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.tradePriceMarkup" placeholder="请输入" addonAfter="%" :disabled="formDisabled" @input="handleRateInput(arguments[0].target.value, 'tradePriceMarkup')" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>1、加价率 = (供货价 - 成本价) / 成本价 * 100%。</div>
                  <div class='tips'>2、在成本价的基础上，根据加价率自动算出供货价。</div>
                  <div class='tips'>3、如果上级成本价有变动，会根据加价率，供货价自动进行相应的变动。</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="供货价" prop="tradePrice" :rules="[
            ...decimalValidate,
            { validator: validator.tradePriceValidate }
          ]">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.tradePrice" placeholder="请输入" addonBefore="¥" :disabled="formDisabled" @input="handlePriceInput(arguments[0].target.value, 'tradePrice')" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>分销商拿货的价格。</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="零售价加价率：" prop="priceMarkup" :rules="rateValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.priceMarkup" placeholder="请输入" addonAfter="%" :disabled="formDisabled||(countryUnifyFlag&&isCollect)" @input="handleRateInput(arguments[0].target.value, 'priceMarkup')" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>1、零售价加价率 = (零售价 - 供货价) / 供货价 * 100%。</div>
                  <div class='tips'>2、在供货价的基础上，根据加价率自动算出零售价。</div>
                  <div class='tips'>3、如果上级成供货价变动，会根据零售价加价率，自动进行变更零售价。</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="零售价" prop="price" :rules="[
            ...decimalValidate,
            { validator: validator.priceValidate }
          ]">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.price" placeholder="请输入" addonBefore="¥" :disabled="formDisabled||(countryUnifyFlag&&isCollect)" @input="handlePriceInput(arguments[0].target.value, 'price')" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>1、零售价格，小程序显示的商品价格。</div>
                  <div class='tips'> 2、分销商采集时，作为建议零售价格。</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="划线价加价率：" prop="originalPriceMarkup" :rules="rateValidate">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.originalPriceMarkup" placeholder="请输入" addonAfter="%" :disabled="formDisabled||(countryUnifyFlag&&isCollect)" @input="handleRateInput(arguments[0].target.value, 'originalPriceMarkup')" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>1、划线价加价率 = (划线价 - 供货价) / 供货价 * 100%。</div>
                  <div class='tips'>2、在供货价的基础上，根据加价率自动算出划线价。</div>
                  <div class='tips'>3、如果上级成供货价变动，会根据划线价加价率，自动进行变更划线价。</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span='8'>
          <a-form-model-item :label-col="{span:9}" :wrapper-col="{span:15}" label="划线价" prop="originalPrice" :rules="[
            ...decimalValidate,
            { validator: validator.originPricePriceValidate }
          ]">
            <div class='flex-a-c'>
              <a-input v-model.trim="form.originalPrice" placeholder="请输入" addonBefore="¥" :disabled="formDisabled||(countryUnifyFlag&&isCollect)" @input="handlePriceInput(arguments[0].target.value, 'originalPrice')" />
              <a-popover>
                <div slot="content">
                  <div class='tips'>小程序的划线价格，商品的原价。</div>
                </div>
                <a-icon type="question-circle" class='question-circle' />
              </a-popover>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>

    </a-form-model>
    <a-row style='margin:12px 0 0;' align='middle' type='flex'>
      <a-col :span='3' style='text-align:right;'>精度值：</a-col>
      <a-col :span='21'>
        <a-radio-group v-model="roundingType" @change="changeRoundingType" :disabled="countryUnifyFlag&&isCollect">
          <a-radio :value="0">
            保留整数
          </a-radio>
          <a-radio :value="1">
            保留1位小数
          </a-radio>
          <a-radio :value="2">
            保留2位小数
          </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
    <a-row :gutter='24' style='margin:12px 0 20px;'>
      <a-col :span='21' :offset='3' style="padding:0;color: rgba(0, 0, 0, 0.45);">
        <div>精度值设置只作用于“零售价”，“划线价”，且精度取舍不影响加价率。</div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref, watch, reactive } from '@vue/composition-api'
import pick from 'lodash/pick'
import NP from 'number-precision'

const decimalValidate = [
  { required: true, message: '请输入' },
  { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式有误' },
]
const weightValidate = [
  { required: true, message: '请输入' },
  { pattern: /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式有误' },
]
const rateValidate = [
  { required: true, message: '请输入' },
  { pattern: /^(([0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式有误' },
]
const pointsValidate = [
  { pattern: /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式有误' },
]
const beansValidate = [
  { required: true, message: '请输入' },
  { pattern: /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式有误' },
]

const radioValidate = [{ required: true, message: '请选择' }]

export default {
  name: 'SkuNormalSetting',

  props: {
    needEdit: Array,
    getDefaultObj: Function,
    typeRateFormat: Function,
    formDisabled: Boolean,
    initData: Object,
    isCollect: Boolean,
    getPriceByTax: Function,
    getMathRound: Function,
    countryUnifyFlag: Boolean,
  },

  setup (props, { root }) {
    const formModelRef = ref(null)
    const form = ref(props.getDefaultObj())
    console.log(form.value)
    const hasInit = ref(!(root.$route.params.productId || root.$route.params.params))
    const roundingType = ref(2)
    watch(
      () => props.initData,
      async (data) => {
        const { specList, skuList, productGeneral } = data
        if (skuList && skuList[0]) {
          if (!specList.length) {
            roundingType.value = skuList[0].roundingType
            Object.assign(
              form.value,
              props.typeRateFormat(
                {
                  stock: String(productGeneral.stock),

                  weight: productGeneral.weight,
                  ...pick(
                    skuList[0],
                    props.needEdit.map((x) => x.variable)
                  ),
                },
                true
              )
            )
          }
          await root.$nextTick()
          hasInit.value = true
          if (root.$route.params.params) handleSupplyPriceInput()
        }
      },
      { immediate: true }
    )
    watch(
      () => props.formDisabled,
      (newVal) => {
        if (newVal) formModelRef.value.clearValidate()
      }
    )

    // 表单校验
    const validator = reactive({
      tradePriceValidate: (rule, value, callback) => {
        if (value && form.value.supplyPrice && +value < +form.value.supplyPrice) {
          callback(new Error('不可小于成本价'))
        } else {
          callback()
        }
      },
      priceValidate: (rule, value, callback) => {
        if (value && form.value.tradePrice && +value < +form.value.tradePrice) {
          callback(new Error('不可小于供货价'))
        } else {
          callback()
        }
      },
      originPricePriceValidate: (rule, value, callback) => {
        if (value && form.value.price && +value < +form.value.price) {
          callback(new Error('不可小于零售价'))
        } else {
          callback()
        }
      },
    })

    // 根据供货价计算划线价/零售价
    watch(
      () => form.value.tradePrice,
      (newVal) => {
        if (!hasInit.value || !newVal) return
        if (props.countryUnifyFlag && props.isCollect) {
          handlePriceInput(form.value.price, 'price')
          handlePriceInput(form.value.originalPrice, 'originalPrice')
        } else {
          formModelRef.value.validateField('tradePrice', (errorMsg) => {
            if (errorMsg) return
            ;['originalPriceMarkup', 'priceMarkup'].forEach((x) => {
              formModelRef.value.validateField(x, (errorMessage) => {
                if (!errorMessage) {
                  const variable = x.split('Markup')[0]
                  form.value[variable] = props.getPriceByTax(
                    newVal,
                    NP.divide(form.value[x], 100),
                    roundingType.value
                  )
                }
              })
            })
          })
        }
      }
    )

    // 成本价改变
    function handleSupplyPriceInput () {
      formModelRef.value.validateField('supplyPrice', (errorMsg) => {
        if (errorMsg || +form.value.supplyPrice === 0) return
        formModelRef.value.validateField('tradePriceMarkup', (errorMessage) => {
          if (!errorMessage) {
            form.value.tradePrice = props.getPriceByTax(
              form.value.supplyPrice,
              NP.divide(form.value.tradePriceMarkup, 100)
            )
          }
        })
      })
    }

    // 加价率改变计算价格
    function handleRateInput (value, variable) {
      if (!form.value.tradePrice) return
      formModelRef.value.validateField(variable, (errorMsg) => {
        if (errorMsg) return
        const priceVariable = variable.split('Markup')[0]
        form.value[priceVariable] = props.getPriceByTax(
          priceVariable === 'tradePrice' ? form.value.supplyPrice : form.value.tradePrice,
          NP.divide(value, 100),
          roundingType.value
        )
      })
    }

    // 价格改变计算加价率
    function handlePriceInput (value, variable) {
      if (variable === 'tradePrice' && !form.value.supplyPrice) return
      formModelRef.value.validateField(variable, (errorMsg) => {
        if (errorMsg) return
        const markup = NP.round(
          NP.times(
            NP.minus(
              NP.divide(
                value,
                variable === 'tradePrice' ? form.value.supplyPrice : form.value.tradePrice
              ),
              1
            ),
            100
          ),
          2
        )
        form.value[`${variable}Markup`] = markup < 0 ? 0 : markup
      })
    }

    function handleSubmit () {
      return new Promise((resolve, reject) => {
        formModelRef.value.validate((valid) => {
          if (valid) {
            const skuList = props.typeRateFormat(form.value)
            const initSku =
              props.initData && props.initData.skuList && props.initData.skuList[0]
                ? props.initData.skuList[0]
                : {}
            const collectProductSkuId = initSku.collectProductSkuId || ''
            const supplyProductSkuId = initSku.supplyProductSkuId || ''

            resolve({
              specList: [],
              specOptionList: [],
              skuList: [
                {
                  ...skuList,
                  collectProductSkuId:
                    collectProductSkuId === initSku.productSkuId ? '' : collectProductSkuId,
                  supplyProductSkuId:
                    supplyProductSkuId === initSku.productSkuId ? '' : supplyProductSkuId,
                  productSkuGeneralId: initSku.productSkuGeneralId || '',
                },
              ],
              changeFlag: getChangeFlag(skuList),
              anyPriceChangeFlag: getAnyPriceChangeFlag([skuList]),
            })
          } else {
            reject(new Error('表单校验失败'))
          }
        })
      })
    }
    // 判断 sku 任意价格变动 或 sku 增删
    function getAnyPriceChangeFlag (params) {
      if (!Object.keys(props.initData).length) return false
      const { skuList } = props.initData
      if (skuList.length !== params.length) return true
      for (let i = 0; i < skuList.length; i++) {
        const arr = ['price', 'originalPrice', 'supplyPrice', 'tradePrice']
        for (let j = 0; j < arr.length; j++) {
          let variable = arr[j]
          if (skuList[i][variable] !== params[i][variable]) {
            return true
          } else {
            return false
          }
        }
      }
      return false
    }
    // 判断sku价格是否发生更改
    function getChangeFlag (newSkuList) {
      if (!Object.keys(props.initData).length) return false
      const { skuList } = props.initData
      for (let { variable } of props.needEdit) {
        if (skuList[0][variable] !== newSkuList[variable]) {
          console.log(variable)
          console.log(+skuList[0][variable], newSkuList[variable])
          return true
        }
      }
      return false
    }
    function changeRoundingType (e) {
      const precision = Number(e.target.value)
      if (form.value.price) {
        form.value.price = props.getPriceByTax(
          form.value.tradePrice,
          NP.divide(form.value.priceMarkup, 100),
          precision
        )
      }
      if (form.value.originalPrice) {
        form.value.originalPrice = props.getPriceByTax(
          form.value.tradePrice,
          NP.divide(form.value.originalPriceMarkup, 100),
          precision
        )
      }
      form.value.roundingType = precision
    }
    return {
      decimalValidate,
      rateValidate,
      formModelRef,
      form,
      validator,
      handleSupplyPriceInput,
      handleRateInput,
      handlePriceInput,
      handleSubmit,
      radioValidate,
      roundingType,
      pointsValidate,
      beansValidate,
      changeRoundingType,
      weightValidate,
    }
  },
}
</script>

<style lang="less" scoped>
.unified-setting {
  ::v-deep .ant-form-item-label {
    width: 110px;
  }
  ::v-deep .ant-input-group-wrapper {
    width: 200px;
  }
}
.tips {
  line-height: 24px;
  font-size: 14px;
  color: #333;
}
.question-circle {
  margin-left: 12px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
</style>
